.header {
  width: 100%;
  z-index: 5;
  padding: var(--spacerLg) 0;
}

.headerInner {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  padding: var(--spacerSm) var(--spacerLg);
}

.main {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacerLg);
  padding: var(--spacerXl) var(--spacerXxl);
  box-shadow: var(--softShadow);
  border-radius: var(--spacerLg);
  max-width: 400px;
  background-color: var(--backgroundFocus);
}
