.link {
  text-align: center;
  margin: 0;
  font-size: var(--fontSm);
  position: relative;
  z-index: 1;
  color: var(--foreground);
  white-space: nowrap;
}

.link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(0.1);
  transform-origin: bottom center;
  transition: 250ms ease-in-out transform;
  background: var(--primary);
  z-index: -1;
}
.link:hover {
  color: var(--textOnPrimary);
}

.link:hover::after {
  transform: scaleY(0.9);
}
