.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin: 0;
  font-size: var(--fontMd);
  box-sizing: border-box;
  width: fit-content;
  max-width: 60ch;
  min-width: fit-content;
}

.text {
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.success {
  color: var(--success);
}

.error {
  color: var(--error);
}

.info {
  color: var(--foreground);
  font-weight: 300;
}

.unbound {
  -webkit-line-clamp: unset;
}

.loader {
  width: 3rem;
  height: 3rem;
}

.notification img,
.notification svg {
  margin-right: var(--spacerSm);
}
