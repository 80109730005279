.form {
  display: flex;
  flex-direction: column;
  font-family: inherit;
  row-gap: var(--spacerLg);
  width: 100%;
}

.label {
  font-family: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--fontSm);
  font-weight: 600;
  color: var(--foregroundAlt);
  flex-grow: 1;
}

.input {
  -webkit-appearance: none;
  font-family: inherit;
  margin-top: var(--spacerXs);
  padding: var(--spacerSm);
  font-size: var(--fontMd);
  box-shadow: 0px 0px 0px 1px var(--backgrounder);
  border: none;
  outline: none;
  font-weight: 300;
  transition: box-shadow 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(--backgroundFocus);
  color: var(--foreground);
  width: 100%;
  border-radius: var(--spacerXs);
}

.largeInput {
  font-size: var(--fontLg);
  margin-top: var(--spacerSm);
}

select.input {
  -webkit-appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding: var(--spacerSm) var(--spacerXl) var(--spacerSm) var(--spacerSm);
}

textarea.input {
  resize: vertical;
}

.inputInfo {
  font-weight: 300;
  padding-top: var(--spacerXs);
}

.input option {
  font-weight: 300;
}

.input optgroup {
  font-weight: 300;
}

.input:focus {
  box-shadow: 0px 0px 0.5px 2px var(--primary);
}

.form > button[type="submit"] {
  width: 100%;
  justify-content: center;
}

.inlineForm {
  display: flex;
  flex-direction: row;
  gap: var(--spacerLg);
  flex-wrap: wrap;
  align-items: flex-end;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: var(--spacerLg);
  flex-wrap: wrap;
  row-gap: var(--spacerLg);
}

@mixin track {
  cursor: pointer;
  height: var(--spacerXs);
  width: 100%;
}

@mixin thumb {
  background: var(--backgroundFocus);
  border: var(--spacerXs) solid var(--primary);
  box-sizing: border-box;
  cursor: pointer;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  height: var(--spacerXl);
  width: var(--spacerXl);
  border-radius: 3rem;
}

input[type="range"].slider {
  -webkit-appearance: none;
  touch-action: none;
  background: transparent;
  margin: var(--spacerMd) 0 0 0;
  padding: calc(var(--spacerMd) + var(--spacerXs)) 0;
  width: 100%;

  &::-moz-focus-outer {
    border: 0;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: calc(var(--spacerXs) / 2 - var(--spacerXl) / 2);
    box-shadow:
      0px 0px 0px 0.5px var(--mid),
      var(--softShadow);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: calc(var(--spacerXs) / 4);
  }

  &:focus {
    outline: 0;

    &::-moz-range-thumb {
      @include thumb;
      background-color: var(--primary);
    }

    &::-webkit-slider-thumb {
      @include thumb;
      -webkit-appearance: none;
      margin-top: calc(var(--spacerXs) / 2 - var(--spacerXl) / 2);
      background-color: var(--primary);
      box-shadow:
        0px 0px 0px 0.5px var(--mid),
        var(--softShadow);
    }

    &::-ms-thumb {
      @include thumb;
      margin-top: calc(var(--spacerXs) / 4);
      background-color: var(--primary);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    background: linear-gradient(
      to right,
      var(--primary) var(--percentage),
      var(--foreground) var(--percentage)
    );
  }

  &::-moz-range-track {
    @include track;
    background: var(--foreground);
    height: var(--spacerXs);
  }

  &::-moz-range-progress {
    @include track;
    background: var(--primary);
    height: var(--spacerXs);
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: calc(var(--spacerXl) / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: var(--primary);
  }

  &::-ms-fill-upper {
    background: var(--foreground);
  }
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--spacerSm);
  font-size: var(--fontSm);
  font-weight: 400;
  color: var(--foregroundAlt);
  cursor: pointer;
}

.input:disabled {
  background-color: var(--background);
  cursor: not-allowed;
}

input[type="checkbox"].toggle {
  position: relative;
  appearance: none;
  width: calc(var(--spacerXl) + var(--spacerSm));
  height: var(--spacerXl);
  margin: 0;
  background-color: var(--background);
  border-radius: 50px;
  box-shadow: var(--insetSoftShadow);
  cursor: pointer;
  margin: 0;
  transition: background-color 0.4s;
}

input:checked[type="checkbox"].toggle {
  background: var(--primary);
}

input[type="checkbox"].toggle::after {
  position: absolute;
  content: "";
  width: var(--spacerXl);
  height: var(--spacerXl);
  top: 0;
  left: calc(-1 * var(--spacerMd) / 4);
  background-color: var(--backgroundFocus);
  border-radius: 50%;
  box-shadow:
    0px 0px 0px 0.5px var(--mid),
    var(--softShadow);
  transform: scale(1.1);
  transition: left 0.4s;
}

input:checked[type="checkbox"].toggle::after {
  left: 50%;
}

.inputWithButtonContainer {
  position: relative;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputButton {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--primary);
}
