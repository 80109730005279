.buttonPrimary {
  cursor: pointer;
  font-family: inherit;
  width: fit-content;
  box-sizing: border-box;
  border: 1px solid var(--foregroundAlt);
  background: var(--primary)
    linear-gradient(45deg, var(--primary), var(--primaryHover));
  color: var(--textOnPrimary);
  font-weight: 600;
  padding: var(--spacerSm) var(--spacerMd);
  border-radius: var(--spacerSm);
  display: flex;
  column-gap: 0.2rem;
  align-items: center;
  font-size: var(--fontMd);
  background-position: center;
  transition: background 0.8s;
  pointer-events: auto;
}

.buttonPrimary:not(:disabled):hover {
  background: var(--primaryHover)
    radial-gradient(circle, transparent 1%, var(--primary) 1%) center/15000%;
}

.buttonPrimary:not(:disabled):active {
  background-color: var(--primary);
  background-size: 100%;
  transition: background 0s;
}

.buttonPrimary:disabled {
  color: var(--background);
  background: var(--midder);
  cursor: not-allowed;
}

.buttonSecondary {
  cursor: pointer;
  font-family: inherit;
  width: fit-content;
  box-sizing: border-box;
  background-color: var(--backgroundFocus);
  color: var(--foreground);
  border: 1px solid var(--backgrounder);
  font-weight: 600;
  padding: var(--spacerSm) var(--spacerMd);
  border-radius: var(--spacerSm);
  column-gap: 0.2rem;
  display: flex;
  align-items: center;
  font-size: var(--fontMd);
  background-position: center;
  transition: background 0.8s;
  pointer-events: auto;
}

.buttonSecondary:not(:disabled):hover {
  background: var(--background)
    radial-gradient(circle, transparent 1%, var(--background) 1%) center/15000%;
}

.buttonSecondary:not(:disabled):active {
  background-color: var(--backgroundFocus);
  background-size: 100%;
  transition: background 0s;
}

.buttonSecondary:disabled {
  color: var(--background);
  cursor: not-allowed;
}

.actionButton {
  border: none;
  background-color: var(--backgroundFocus);
  color: var(--foreground);
  cursor: pointer;
  padding: var(--spacerSm);
  margin: 0;
  border-radius: 3rem;
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  column-gap: 0.2rem;
  font-size: var(--fontSm);
  font-weight: 400;
  transition: background 0.8s;
  pointer-events: auto;
}

.actionButtonPrimary {
  box-shadow: 0px 0px 1px 1px var(--primary);
}

.actionButtonError {
  box-shadow: 0px 0px 0.5px 1px var(--error);
}

.actionButtonError > svg {
  color: var(--error);
}

.actionButton:not(:disabled):hover {
  background: var(--background)
    radial-gradient(circle, transparent 1%, var(--background) 1%) center/15000%;
}

.actionButton:not(:disabled):active {
  background-color: var(--backgroundFocus);
  background-size: 100%;
  transition: background 0s;
}

.actionButton:disabled {
  color: var(--background);
}

.buttonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--spacerSm);
  pointer-events: all;
}

.floatingActionButtonLink {
  font-size: var(--fontMd);
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  padding: var(--spacerMd) var(--spacerLg);
  gap: var(--spacerSm);
  cursor: pointer;
  font-family: inherit;
  color: var(--foreground);
  font-weight: 400;
}
