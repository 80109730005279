.container {
  display: flex;
  align-items: center;
  position: relative;
}

.indicator {
  position: absolute;
  top: -14px;
  right: -14px;
  background-color: var(--error);
  color: var(--backgroundFocus);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontXs);
  text-align: center;
  font-weight: 600;
}
