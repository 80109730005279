.dialog::backdrop {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 98;
}

.dialog {
  border: none;
  width: calc(100% - var(--spacerLg));
  padding: var(--spacerXxl);
  border-radius: var(--spacerLg);
  align-self: center;
  justify-self: center;
}

.dialogHeader {
  position: absolute;
  right: 0;
  top: 0;
  padding: var(--spacerXs);
  z-index: 99;
}

.dialog[open] {
  animation: show 0.3s ease-in-out normal;
}

@keyframes show {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
