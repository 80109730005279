.input {
  appearance: none;
  margin: 0;
  width: 0;
  height: 0;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--fontSm);
  font-weight: 600;
  color: var(--foregroundAlt);
  cursor: pointer;
}

.checkbox {
  width: var(--spacerLg);
  height: var(--spacerLg);
  border-radius: 3px;
  padding: 0;
  box-shadow: 0px 0px 0px 1px var(--mid);
  margin-right: var(--spacerXs);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--backgroundFocus);
  transition: background 0.3s;
}

.checkbox > svg {
  font-size: 1.2em;
}

.checkboxUnchecked {
}

.checkboxChecked {
  background-color: var(--primary);
  color: var(--foreground);
  box-shadow: 0px 0px 0px 1px var(--foregroundAlt);
}
