.footer {
  padding: var(--spacerMd);
  font-size: var(--fontSm);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacerMd);
  flex-wrap: wrap;
}

.innerFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacerMd);
}

.footerLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacerMd);
}
