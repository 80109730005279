.main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--spacerMd);
  height: calc(100% - 2 * var(--spacerMd));
}

.innerMain {
  max-width: 1000px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: var(--spacerLg) var(--spacerMd);
  box-shadow: var(--softShadow);
  border-radius: var(--spacerLg);
  background-color: var(--backgroundFocus);
  margin: auto;
  text-align: center;
}

.loadingContainer {
  margin: auto var(--spacerMd);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topPadding {
  padding-top: var(--spacerMd);
}

.eula {
  border: none;
  height: 90%;
  width: 90%;
}

.pdfcontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: none;
  height: 90%;
  width: 90%;
  overflow-y: scroll;
  background-color: var(--background);
}

.paragraph {
  margin: 0;
  font-weight: 300;
  color: var(--foreground);
  font-size: var(--fontMd);
}
