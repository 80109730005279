.main {
  margin: auto var(--spacerMd);
}

.innerMain {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacerLg);
  padding: var(--spacerXl) var(--spacerXxl);
  box-shadow: var(--softShadow);
  border-radius: var(--spacerLg);
  background-color: var(--backgroundFocus);
  margin: auto;
}

.loadingContainer {
  margin: auto var(--spacerMd);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
