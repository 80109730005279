.themeProvider {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--foreground);
  background-color: var(--background);
}

.lightTheme {
  --mid: #b3b3b3;
  --midder: #999;
  --backgrounder: #cdcdcd;
  --background: #eee;
  --backgroundTransparent: #eeeeee99;
  --oppositeBackground: #242424;
  --oppositeBackgroundTransparent: #24242464;
  --backgroundMid: #f4f4f4;
  --backgroundFocus: #fff;
  --backgroundFocusTransparent: #ffffffdd;
  --foreground: #2b2525;
  --foregroundAlt: #3f3c3c;
  --foregroundAltLite: #5f5e5e;
  --primary: #e9b321;
  --primaryBg: #fdfdf4;
  --primarySemiOpaque: #e9b32199;
  --primaryBright: #e9b321;
  --textOnPrimary: #2b2525;
  --primaryHover: #ffc422;
  --headerShadow: 0px 2px 3px #00000022, 0px 5px 8px #00000047;
  --appFocusShadow: 0px 0px 3px #00000022, 0px 0px 8px #00000047;
  --softShadow: 1px 1px 2px #00000012, 3px 4px 5px #00000016;
  --insetSoftShadow: inset 1px 1px 2px #00000012, inset 3px 4px 5px #00000016;
  --error: #b52a2a;
  --errorBg: #ffdede;
  --success: #158e8e;
  --successBg: #e0fcf0;
  --secondary: #466b82;
}

.genericAffiliateTheme {
  --mid: #b3b3b3;
  --midder: #999;
  --backgrounder: #cdcdcd;
  --background: #eee;
  --backgroundTransparent: #eeeeee99;
  --oppositeBackground: #242424;
  --oppositeBackgroundTransparent: #24242464;
  --backgroundMid: #f4f4f4;
  --backgroundFocus: #fff;
  --backgroundFocusTransparent: #ffffffdd;
  --foreground: #1e1e1e;
  --foregroundAlt: #525252;
  --primary: #6667ab;
  --primarySemiOpaque: #6667ab99;
  --primaryBright: #9b9cda;
  --textOnPrimary: #fff;
  --primaryHover: #7172b9;
  --headerShadow: 0 0 0 1px #fff;
  --appFocusShadow: 0px 0px 3px #00000022, 0px 0px 8px #00000047;
  --softShadow: 0 0 0 0.5px #cdcdcd;
  --insetSoftShadow: inset 1px 1px 2px #00000012, inset 3px 4px 5px #00000016;
  --error: #be3a34;
  --errorBg: #f8c3c0;
  --success: #0b4f30;
  --successBg: #cdf5e3;
  --secondary: #f79256;
}
