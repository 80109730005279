.printContent {
  display: none;
}

.regularContent {
  display: unset;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media print {
  .printContent {
    display: unset;
  }
  .regularContent {
    display: none;
  }
  @page {
    size: auto;
    margin: 0;
  }
}
