.container {
  height: 100%;
  width: 100%;
}

.loadingContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 160px;
  height: 160px;
  margin-bottom: 60px;
}

.spinner g[clip-path] > g:last-child path {
  stroke: var(--primary);
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--error);
  margin-bottom: 60px;
}
.error > svg {
  width: 50px;
  height: 50px;
}
