html,
body {
  --spacerXs: 0.375rem;
  --spacerSm: 0.5rem;
  --spacerMd: 0.75rem;
  --spacerLg: 1rem;
  --spacerXl: 1.5rem;
  --spacerXxl: 2rem;
  --fontXs: 0.7rem;
  --fontSm: 0.8rem;
  --fontMd: 1rem;
  --fontLg: 1.5rem;
  --fontXl: 2rem;
  --mid: #b3b3b3;
  --backgrounder: #cdcdcd;
  --background: #eee;
  --backgroundTransparent: #eeeeee99;
  --oppositeBackground: #242424;
  --oppositeBackgroundTransparent: #24242464;
  --backgroundMid: #f4f4f4;
  --backgroundFocus: #fff;
  --backgroundFocusTransparent: #ffffffdd;
  --foreground: #2b2525;
  --foregroundAlt: #918e8e;
  --primary: #e9b321;
  --primarySemiOpaque: #e9b32199;
  --primaryHover: #ffc422;
  --headerShadow: 0px 2px 3px #00000022, 0px 5px 8px #00000047;
  --appFocusShadow: 0px 0px 3px #00000022, 0px 0px 8px #00000047;
  --softShadow: 1px 1px 2px #00000012, 3px 4px 5px #00000016;
  --insetSoftShadow: inset 1px 1px 2px #00000012, inset 3px 4px 5px #00000016;
  --error: #b52a2a;
  --errorBg: #ffdede;
  --success: #158e8e;
  --successBg: #e0fcf0;
  --secondary: #466b82;

  color: var(--foreground);
  background-color: #eee;
  padding: 0;
  margin: 0;
  font-family:
    soleil,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  height: 100%;
}

@media (max-width: 480px) {
  body,
  html {
    --spacerXs: 0.2rem;
    --spacerSm: 0.45rem;
    --spacerMd: 0.6rem;
    --spacerLg: 0.8rem;
    --spacerXl: 1.2rem;
    --spacerXxl: 1.5rem;
    --fontXs: 0.85rem;
    --fontSm: 0.9rem;
    --fontMd: 1rem;
    --fontLg: 1.1rem;
    --fontXl: 1.5rem;
  }
}

#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mainAppContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

fieldset legend {
  display: none;
}

strong,
b {
  font-weight: 700;
}
