.logoText {
  color: var(--backgroundFocus);
  font-weight: 600;
  font-size: var(--fontXl);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacerXs);
}

.logoText > svg {
  font-size: 1.25em;
}
