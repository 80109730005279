.feedbackLink {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(35%) rotate(-90deg);
  transition:
    background 0.8s,
    transform 01s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: var(--softShadow);
  z-index: 3;
}

.feedbackLink:hover {
  transform: translate(32%) rotate(-90deg);
}
