.header {
  width: 100%;
  z-index: 5;
  background-color: var(--oppositeBackground);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacerSm) var(--spacerLg);
  box-shadow: var(--headerShadow);
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacerLg);
  color: var(--backgroundFocus);
}

.subTitle {
  margin: 0;
  font-size: var(--fontSm);
  font-weight: 400;
  color: var(--background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.subTitle > svg {
  color: var(--mid);
  font-size: 1.4em;
  margin-right: 2px;
  margin-top: 1.5px;
}

.title {
  margin: 0;
  font-size: var(--fontMd);
  font-weight: 600;
}
